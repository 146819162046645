import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import VacationRequestForm from "../../../components/forms/employees/VacationRequestForm";
import { updateObjectInArray } from "../../../shared/utility";
import DeleteConfirmationForm from "../../../components/forms/DeleteConfirmationForm";

export function VacationDaysFormViewModel(employeeId, logout) {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [vacationDays, setVacationDays] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getVacationDays = async function (search, page) {
    setLoading(true);
    const authData = {
      action: "fetchEmployeeVacationDays",
      userId: 1,
      search: search,
      pageNumber: page,
      employee_id: employeeId,
    };
    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setVacationDays(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createVacationDay = async function (data) {
    let callResult = await makePostApiCall(
      { ...data, employee_id: employeeId },
      "multipart/form-data",
      "vacation-days/vacation-day/createVacationDay.php"
    );

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      getVacationDays(null, 0);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddVacationDaySlideOver = () => {
    setSlideOver({
      childComponent: (
        <VacationRequestForm
          actionCall={createVacationDay}
          employeeId={employeeId}
          isEdit={false}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  const editVacationDay = async function (data, index) {
    let callResult = await makePostApiCall(
      { ...data, employee_id: employeeId },
      "multipart/form-data",
      "vacation-days/vacation-day/editVacationDay.php"
    );

    if (callResult.success) {
      const currentVacationDays = [...vacationDays.data];
      const updateVacationDay = {
        ...callResult.data,
      };

      const updatePayload = {
        index: index,
        item: updateVacationDay,
      };

      const updatedVacationDays = updateObjectInArray(
        currentVacationDays,
        updatePayload
      );

      const newVacationDays = { ...vacationDays, data: updatedVacationDays };
      setVacationDays(newVacationDays);

      setSlideOver({
        childComponent: null,
        open: false,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openEditVacationDaySlideOver = (vacation, index) => {
    setSlideOver({
      childComponent: (
        <VacationRequestForm
          actionCall={editVacationDay}
          employeeId={employeeId}
          index={index}
          vacation={vacation}
          isEdit={true}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  const deleteVacationDayCall = async function (vacation_id) {
    const authData = {
      action: "deleteVacationDay",
      vacation_id: vacation_id,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      getVacationDays(null, 0);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setConfirmationModalOpen({
      open: false,
      childComponent: null,
    });
  };

  const deleteVacationDayConfirm = (vacation_id) => {
    setConfirmationModalOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage={"Delete"}
          confirmAction={() => deleteVacationDayCall(vacation_id)}
          setOpen={setConfirmationModalOpen}
          title={"Are you sure you want to delete vacation request?"}
        />
      ),
      open: true,
    });
  };

  return {
    confirmationModalOpen,
    deleteVacationDayConfirm,
    vacationDays,
    getVacationDays,
    loading,
    openAddVacationDaySlideOver,
    openEditVacationDaySlideOver,
    setConfirmationModalOpen,
    setSlideOver,
    slideOver,
    setSuccessNotification,
    successNotification,
  };
}
