import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { classNames, getLastItem, withRouter } from "../../../shared/utility";

import { EmployeeScreenViewModel } from "../../../viewmodels//employees/employee/EmployeeScreenViewModel";
import EmployeeDetailsForm from "../../../components/forms/employees/EmployeeDetailsForm";
import EmployeeDocumentsScreen from "./EmployeeDocumentsScreen";
import EmployeeVacationDays from "./EmployeeVacationDays";
import SuccessNotification from "../../../components/SuccessNotification";

const tabs = [
  { name: "Employee Details", href: "#" },
  { name: "Forms", href: "#" },
  { name: "Vacation requests", href: "#" },
];

function EmployeeScreen(props) {
  const { logout } = props;

  const [currentTab, setCurrentTab] = useState(0);

  const { pathname } = useLocation();
  const employeeId = getLastItem(pathname);

  const {
    employee,
    fetchStoresDropdown,
    getEmployeeDetails,
    loading,
    setSuccessNotification,
    successNotification,
    updateEmployee,
  } = EmployeeScreenViewModel(logout, employeeId);

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <div key={0} className="-mt-2 bg-white">
            {loading ? (
              "loading"
            ) : (
              <EmployeeDetailsForm
                actionCall={updateEmployee}
                employee={employee}
                isEdit={true}
                fetchStoresDropdown={fetchStoresDropdown}
                setOpen={() => {}}
              />
            )}
          </div>
        );

      case 1:
        return (
          <div key={1}>
            <EmployeeDocumentsScreen employeeId={employeeId} />
          </div>
        );

      case 2:
        return (
          <div key={2}>
            <EmployeeVacationDays employeeId={employeeId} />
          </div>
        );

      default:
        return (
          <div key={1000} className="-mt-2 bg-white">
            Could not load page, please try again
          </div>
        );
    }
  };

  return (
    <div className="bg-gray-50">
      <header className="bg-white shadow sticky top-0 z-50">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex px-4 sm:px-12">
            <h1 className="text-3xl font-bold text-gray-900">
              {loading
                ? "Loading..."
                : employee
                ? employee.fname + " " + employee.lname
                : null}
            </h1>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Content starts here */}

          {/* tab here */}
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full  border-gray-300 rounded-md"
                defaultValue={tabs[currentTab].name}
              >
                {tabs.map((tab, index) => (
                  <option key={index}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                aria-label="Tabs"
              >
                {tabs.map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      index === currentTab
                        ? "text-gray-900"
                        : "text-gray-500 hover:text-gray-700",
                      index === 0 ? "rounded-l-lg" : "",
                      index === tabs.length - 1 ? "rounded-r-lg" : "",
                      "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                    )}
                    aria-current={index === currentTab ? "page" : undefined}
                    onClick={() => setCurrentTab(index)}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        index === currentTab
                          ? "bg-indigo-500"
                          : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* {tab content starts below} */}
          <div className="px-4 py-6 sm:px-0">
            <div className="sm:col-span-9 py-5 border-b border-gray-200">
              {tabs.map((item, index) =>
                index === currentTab ? renderComponent(index) : null
              )}
            </div>
          </div>
        </div>

        <SuccessNotification
          show={successNotification.show}
          setShow={setSuccessNotification}
          title={successNotification.title}
        />
      </main>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeScreen)
);
