import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { updateObjectInArray } from "../../shared/utility";
import AbsenceRequestForm from "../../components/forms/employees/AbsenceRequestForm";

export function AbsenceFormsViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [absences, setAbsences] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [employeesDropdownOptions, setEmployeesDropdownOptions] = useState([
    { value: -1, label: "Select Employee" },
  ]);
  const [selectedEmployeeFilter, setSelectedEmployeeFilter] = useState(-1);
  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });

  const getAbsences = async function (
    search,
    page,
    employee_id,
    start_date,
    end_date
  ) {
    setLoading(true);
    const authData = {
      action: "fetchAllAbsences",
      userId: 1,
      search: search,
      pageNumber: page,
      employee_id: employee_id,
      start_date: start_date,
      end_date: end_date,
    };

    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setAbsences(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createAbsence = async function (data) {
    let callResult = await makePostApiCall(
      data,
      "multipart/form-data",
      "absences/absence-day/createAbsence.php"
    );

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      getAbsences(null, 0);
      setSuccessNotification({
        show: true,
        title: "Absence request created",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddAbsenceSlideOver = () => {
    setSlideOver({
      childComponent: (
        <AbsenceRequestForm
          employeesDropdownOptions={employeesDropdownOptions}
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createAbsence}
        />
      ),
      open: true,
    });
  };

  const editAbsence = async function (data, index) {
    let callResult = await makePostApiCall(
      data,
      "multipart/form-data",
      "absences/absence-day/editAbsence.php"
    );
    if (callResult.success) {
      const currentAbsences = [...absences.data];
      const updateAbsence = {
        ...data,
        start_date: data.start_date.toISOString(),
        end_date: data.end_date.toISOString(),
      };

      const updatePayload = {
        index: index,
        item: updateAbsence,
      };

      const updatedAbsences = updateObjectInArray(
        currentAbsences,
        updatePayload
      );

      const newAbsences = { ...absences, data: updatedAbsences };

      setAbsences(newAbsences);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setSuccessNotification({
        show: true,
        title: "Form request edited",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openEditAbsenceSlideOver = (absence, index) => {
    setSlideOver({
      childComponent: (
        <AbsenceRequestForm
          employeesDropdownOptions={employeesDropdownOptions}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          absence={absence}
          actionCall={editAbsence}
        />
      ),
      open: true,
    });
  };

  const fetchEmployeesDropdown = async function () {
    const authData = {
      action: "fetchEmployeesDropdown",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setEmployeesDropdownOptions([
        { value: -1, label: "Select Employee" },
        ...callResult.data,
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onEmployeeDropdownSelected = (eOption) => {
    const selectedEmployeeId = eOption.target.value;
    setSelectedEmployeeFilter(selectedEmployeeId);
  };

  const deleteAbsenceDay = async function (form) {
    const authData = {
      action: "deleteAbsence",
      absence_id: form.id,
      new_file_name: form.new_file_name,
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      const updatedDocuments = absences.data.filter(
        (entry) => entry.id !== form.id
      );
      setAbsences({ ...absences, data: updatedDocuments });
      setSuccessNotification({
        show: true,
        title: "Absence deleted",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    deleteAbsenceDay,
    employeesDropdownOptions,
    fetchEmployeesDropdown,
    getAbsences,
    loading,
    onEmployeeDropdownSelected,
    absences,
    openAddAbsenceSlideOver,
    openEditAbsenceSlideOver,
    selectedDate,
    setSelectedDate,
    setSlideOver,
    slideOver,
    selectedEmployeeFilter,
    setSuccessNotification,
    successNotification,
  };
}
