import { useEffect, useRef } from "react";
import { VacationDaysFormViewModel } from "../../../viewmodels/employees/employee/VacationDaysFormViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../../store/actions/index";

import { classNames, formatDate, withRouter } from "../../../shared/utility";

import SlideOver from "../../../components/slideOver/SlideOver";
import SuccessNotification from "../../../components/SuccessNotification";

import ConfirmationModal from "../../../components/modals/ConfirmationModal";

function EmployeeVacationDays(props) {
  const { employeeId, logout } = props;

  const {
    confirmationModalOpen,
    deleteVacationDayConfirm,
    vacationDays,
    getVacationDays,
    loading,
    openAddVacationDaySlideOver,
    openEditVacationDaySlideOver,
    setConfirmationModalOpen,
    setSlideOver,
    slideOver,
    setSuccessNotification,
    successNotification,
  } = VacationDaysFormViewModel(employeeId, logout);

  var currentPage = 1;
  useEffect(() => {
    getVacationDays(null, currentPage);
  }, []);

  const searchRef = useRef();

  const handleSearch = () => {
    getVacationDays(searchRef.current.value, currentPage);
  };

  const handlePagination = (newPage) => {
    currentPage = newPage;
    getVacationDays(searchRef.current.value, newPage);
  };

  return (
    <div>
      <div>
        <div>
          <div className="sm:flex sm:items-center bg-white px-4 py-5 border-b border-gray-200 sm:px-4 rounded-xl">
            <div className="flex sm:flex-auto">
              <h1 className="relative flex items-stretch flex-grow focus-within:z-10 text-base font-semibold leading-6 text-gray-900">
                Vacation Days - Total used: {vacationDays.days_used} - Total
                left: {vacationDays.days_left}
              </h1>
              <button
                type="button"
                className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => openAddVacationDaySlideOver()}
              >
                Request Day
              </button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Start date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            End date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Notes
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {vacationDays.data.map((vacation, index) => (
                          <tr key={vacation.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {formatDate(vacation.start_date)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {formatDate(vacation.end_date)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {vacation.note}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {vacation.statusText}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <button
                                onClick={() => {
                                  openEditVacationDaySlideOver(vacation, index);
                                }}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Edit
                              </button>
                              <a
                                href={
                                  "https://employee-api.eurobutcher.ca/documents/vacation_documents/" +
                                  vacation.current_form_name
                                }
                                target="_blank"
                                rel="noreferrer"
                                download={vacation.current_form_name}
                                className="ml-2 rounded-md bg-yellow-600 px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                              >
                                Download
                              </a>
                              <button
                                onClick={() => {
                                  deleteVacationDayConfirm(vacation.id);
                                }}
                                className="ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        childComponent={confirmationModalOpen.childComponent}
        open={confirmationModalOpen.open}
        setOpen={setConfirmationModalOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-3xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <SuccessNotification
        show={successNotification.show}
        setShow={setSuccessNotification}
        title={successNotification.title}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeVacationDays)
);
