import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";

export function EmployeeScreenViewModel(logout, employeeId) {
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });

  const getEmployeeDetails = async function (search, page) {
    setLoading(true);
    const authData = {
      action: "fetchEmployee",
      userId: 1,
      employeeId: employeeId,
    };
    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setEmployee(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchStoresDropdown = async function () {
    const authData = {
      action: "fetchStoresDropdown",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const updateEmployee = async function (data) {
    const authData = {
      action: "editEmployee",
      data: data,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      setEmployee(callResult.data);
      setSuccessNotification({
        show: true,
        title: "Employee updated",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    employee,
    fetchStoresDropdown,
    getEmployeeDetails,
    loading,
    setSuccessNotification,
    successNotification,
    updateEmployee,
  };
}
