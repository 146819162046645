import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { updateObjectInArray } from "../../shared/utility";
import AssignDocumentForm from "../../components/forms/assignDocuments/AssignDocumentForm";

export function PublicHolidayConsentFormsViewModel(logout) {
  const [employeesDropdownOptions, setEmployeesDropdownOptions] = useState([
    { value: -1, label: "Select Employee" },
  ]);
  const [filters, setFilters] = useState({
    status: null,
  });
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [statusFilters] = useState([
    { value: -1, label: "Select status" },
    { value: 1, label: "Incomplete" },
    { value: 2, label: "Complete" },
  ]);

  const user_id = localStorage.getItem("id") * 1;

  const getDocuments = async function (search, page, status) {
    setLoading(true);
    const authData = {
      action: "fetchDocumentsByType",
      userId: 1,
      search: search,
      pageNumber: page,
      type: 5,
      status: status,
      test: "asd",
    };
    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setForms(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchEmployeesDropdown = async function () {
    const authData = {
      action: "fetchEmployeesDropdown",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setEmployeesDropdownOptions([
        { value: -1, label: "Select Employee" },
        ...callResult.data,
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onAssignDocument = async function (values) {
    const payload = {
      ...values,
      type: 5,
      user_id: user_id,
    };
    let callResult = await makePostApiCall(
      payload,
      "multipart/form-data",
      "assignedDocuments/assignedDocument/assignDocumentByType.php"
    );
    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setSuccessNotification({
        show: true,
        title: "Document assigned",
      });
      getDocuments(null, null, null);
      setFilters({
        status: null,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddAssignDocumentSlideOver = () => {
    setSlideOver({
      childComponent: (
        <AssignDocumentForm
          employeesDropdownOptions={employeesDropdownOptions}
          isEdit={false}
          setOpen={setSlideOver}
          document={document}
          hideName={true}
          actionCall={onAssignDocument}
        />
      ),
      open: true,
    });
  };

  const onCompleteDocument = async function (values, index) {
    const payload = {
      ...values,
      user_id: user_id,
    };
    let callResult = await makePostApiCall(
      payload,
      "multipart/form-data",
      "assignedDocuments/assignedDocument/completeDocument.php"
    );
    if (callResult.success) {
      const currentDocuments = [...forms.data];

      const updateDocument = {
        ...values,
        status_text: callResult.data.status_text,
        completed_date: callResult.data.completed_date,
      };

      const updatePayload = {
        index: index,
        item: updateDocument,
      };

      const updatedDocuments = updateObjectInArray(
        currentDocuments,
        updatePayload
      );

      const newDocuments = { ...forms, data: updatedDocuments };

      setForms(newDocuments);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setSuccessNotification({
        show: true,
        title: "Document completed",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openSubmitDocumentSlideOver = (document, index) => {
    setSlideOver({
      childComponent: (
        <AssignDocumentForm
          employeesDropdownOptions={employeesDropdownOptions}
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          document={document}
          hideName={true}
          actionCall={onCompleteDocument}
        />
      ),
      open: true,
    });
  };

  const deleteDocument = async function (form) {
    const authData = {
      action: "deleteDocument",
      document_id: form.id,
      new_file_name: form.new_file_name,
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      const updatedDocuments = forms.data.filter(
        (entry) => entry.id !== form.id
      );
      setForms({ ...forms, data: updatedDocuments });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const clearFilters = () => {
    getDocuments(null, null, null);
    setFilters({
      status: -1,
    });
  };

  return {
    clearFilters,
    deleteDocument,
    fetchEmployeesDropdown,
    filters,
    forms,
    getDocuments,
    loading,
    openAddAssignDocumentSlideOver,
    openSubmitDocumentSlideOver,
    setFilters,
    setSlideOver,
    slideOver,
    setSuccessNotification,
    successNotification,
    statusFilters,
  };
}
