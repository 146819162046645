import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import EmployeeDetailsForm from "../../components/forms/employees/EmployeeDetailsForm";

export function EmployeesViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getEmployees = async function (search, page) {
    setLoading(true);
    const authData = {
      action: "fetchEmployees",
      userId: 1,
      search: search,
      pageNumber: page,
    };
    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setEmployees(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createEmployee = async function (data) {
    const authData = {
      action: "createEmployee",
      data: data,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      getEmployees(null, 0);
      setSuccessNotification({
        show: true,
        title: "Employee added",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddEmployeeSlideOver = () => {
    setSlideOver({
      childComponent: (
        <EmployeeDetailsForm
          actionCall={createEmployee}
          fetchStoresDropdown={fetchStoresDropdown}
          isEdit={false}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  const fetchStoresDropdown = async function () {
    const authData = {
      action: "fetchStoresDropdown",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    employees,
    fetchStoresDropdown,
    getEmployees,
    loading,
    openAddEmployeeSlideOver,
    setSlideOver,
    slideOver,
    setSuccessNotification,
    successNotification,
  };
}
