import { useEffect } from "react";
import { AbsenceFormsViewModel } from "../../viewmodels/absenceForms/AbsenceFormsViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { formatDate, withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import SuccessNotification from "../../components/SuccessNotification";
import SelectMenu from "../../components/controls/SelectMenu";
import DatePickerInput from "../../components/controls/DatePickerInput";

function AbsenceForms(props) {
  const { logout } = props;
  const {
    deleteAbsenceDay,
    openAddAbsenceSlideOver,
    employeesDropdownOptions,
    fetchEmployeesDropdown,
    getAbsences,
    loading,
    onEmployeeDropdownSelected,
    absences,
    openEditAbsenceSlideOver,
    selectedDate,
    setSelectedDate,
    setSlideOver,
    slideOver,
    selectedEmployeeFilter,
    setSuccessNotification,
    successNotification,
    openAddNewAbsenceSlideOver,
  } = AbsenceFormsViewModel(logout);

  var currentPage = 1;
  useEffect(() => {
    getAbsences(null, currentPage);
    fetchEmployeesDropdown();
  }, []);

  return (
    <div>
      <div className="mt-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center bg-white px-4 py-5 border-b border-gray-200 sm:px-4 rounded-xl">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Absence Requests
              </h1>

              <div className="mt-5 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <div>
                    <SelectMenu
                      handleInputChange={onEmployeeDropdownSelected}
                      name="employee_filter"
                      options={employeesDropdownOptions}
                      width="w-48"
                      selectedOptionValue={employeesDropdownOptions[0].value}
                    />
                  </div>
                  <div className="ml-2">
                    <DatePickerInput
                      name="start_date"
                      onChange={(e) =>
                        setSelectedDate({
                          ...selectedDate,
                          start_date: e.target.value,
                        })
                      }
                      value={selectedDate.start_date}
                      placeholderText="Start Date"
                      labelOn={false}
                      type="text"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="ml-2">
                    <DatePickerInput
                      name="end_date"
                      onChange={(e) =>
                        setSelectedDate({
                          ...selectedDate,
                          end_date: e.target.value,
                        })
                      }
                      value={selectedDate.end_date}
                      placeholderText="End Date"
                      labelOn={false}
                      type="text"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                    />
                  </div>
                  <div className="ml-2">
                    <button
                      type="button"
                      className="ml-1 mt-1 rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                      onClick={() =>
                        getAbsences(
                          "",
                          0,
                          selectedEmployeeFilter,
                          selectedDate.start_date,
                          selectedDate.end_date
                        )
                      }
                    >
                      Filter
                    </button>
                  </div>
                  <div className="ml-2">
                    <button
                      type="button"
                      className="ml-1 mt-1 rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                      onClick={() => getAbsences("", 0)}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => openAddAbsenceSlideOver()}
                >
                  Add Absence Request
                </button>
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Employee Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Start Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            End Date
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Reason
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {absences.data.map((absence, index) => (
                          <tr key={absence.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {absence.fname} {absence.lname}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {formatDate(absence.start_date)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {formatDate(absence.end_date)}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {absence.reasonText}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {absence.statusText}
                            </td>

                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <button
                                onClick={() => {
                                  openEditAbsenceSlideOver(absence, index);
                                }}
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              >
                                Edit
                              </button>
                              <a
                                href={
                                  "https://employee-api.eurobutcher.ca/documents/absence_documents/" +
                                  absence.form_name
                                }
                                target="_blank"
                                rel="noreferrer"
                                download={absence.form_name}
                                className="ml-2 rounded-md bg-yellow-600 px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                              >
                                Download
                              </a>
                              <button
                                onClick={() => {
                                  deleteAbsenceDay(absence, index);
                                }}
                                className="ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-3xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <SuccessNotification
        show={successNotification.show}
        setShow={setSuccessNotification}
        title={successNotification.title}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AbsenceForms)
);
