import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";

export function DashboardViewModel(logout) {
  const [employees, setEmployees] = useState([]);
  const [openDocuments, setOpenDocuments] = useState([]);
  const [stores, setStores] = useState([]);
  const [vacations, setVacations] = useState([]);

  const fetchIncompleteAssignedDocuments = async function () {
    const authData = {
      action: "fetchIncompleteAssignedDocuments",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setOpenDocuments(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchEmployeesForDashboard = async function () {
    const authData = {
      action: "fetchEmployeesForDashboard",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setEmployees(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchStoresForDashboard = async function () {
    const authData = {
      action: "fetchStoresForDashboard",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setStores(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchVacationsForDashboard = async function () {
    const authData = {
      action: "fetchVacationsForDashboard",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setVacations(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    fetchEmployeesForDashboard,
    fetchIncompleteAssignedDocuments,
    fetchStoresForDashboard,
    fetchVacationsForDashboard,
    employees,
    openDocuments,
    stores,
    vacations,
  };
}
