import { ChevronRightIcon } from "@heroicons/react/20/solid";

import {
  ChartPieIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export default function routes(pathname, userType) {
  var title;
  var routes;

  routes = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HomeIcon,
      current: false,
      showInMenu: true,
    },
    {
      name: "Stores",
      href: "/stores",
      icon: ChartPieIcon,
      current: false,
      showInMenu: userType === "1",
    },
    {
      name: "Employees",
      href: "/employees",
      icon: ChartPieIcon,
      current: false,
      showInMenu: userType === "1",
    },
    // {
    //   name: "Documents",
    //   href: "/documents",
    //   icon: UsersIcon,
    //   current: false,
    //   showInMenu: userType === "1",
    // },
    {
      name: "Employee Vacation Requests",
      href: "/vacation-requests",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
    {
      name: "Public Holiday Consent",
      href: "/public-holiday-consent",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
    {
      name: "Absence Requests",
      href: "/absence-forms",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
    {
      name: "Training",
      href: "/training-forms",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
    {
      name: "Benefits",
      href: "/benefits-forms",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
    {
      name: "TD1",
      href: "/td1-forms",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
    {
      name: "Handbook",
      href: "/handbook-forms",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
    {
      name: "Others",
      href: "/other-forms",
      icon: UsersIcon,
      current: false,
      showInMenu: userType === "1",
    },
  ];

  routes.forEach((route) => {
    route.open = false;
    if (route.children) {
      route.children.forEach((child) => {
        child.current = false;
        if (child.href === pathname) {
          route.open = true;
          child.current = true;

          title = child.name;
        }
      });
    } else if (route.href === pathname) {
      route.current = true;

      title = route.name;
    }
  });

  var returnArray = {
    routes: routes.filter((route) => route.showInMenu),
    title: title,
  };

  return returnArray;
}
