import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { updateObjectInArray } from "../../shared/utility";
import VacationRequestForm from "../../components/forms/employees/VacationRequestForm";

export function AllVacationRequestsViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [vacations, setVacations] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [employeesDropdownOptions, setEmployeesDropdownOptions] = useState([
    { value: -1, label: "Select Employee" },
  ]);
  const [selectedEmployeeFilter, setSelectedEmployeeFilter] = useState(-1);
  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });

  const getVacations = async function (
    search,
    page,
    employee_id,
    start_date,
    end_date
  ) {
    setLoading(true);
    const authData = {
      action: "fetchAllVacationDays",
      userId: 1,
      search: search,
      pageNumber: page,
      employee_id: employee_id,
      start_date: start_date,
      end_date: end_date,
    };

    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setVacations(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createVacation = async function (data) {
    let callResult = await makePostApiCall(
      data,
      "multipart/form-data",
      "vacation-days/vacation-day/createVacationDay.php"
    );

    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      getVacations(null, 0);
      setSuccessNotification({
        show: true,
        title: "Vacation request created",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddVacationSlideOver = () => {
    setSlideOver({
      childComponent: (
        <VacationRequestForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createVacation}
        />
      ),
      open: true,
    });
  };

  const editVacation = async function (data, index) {
    let callResult = await makePostApiCall(
      data,
      "multipart/form-data",
      "vacation-days/vacation-day/editVacationDay.php"
    );
    if (callResult.success) {
      const currentVacations = [...vacations.data];
      const updateVacation = {
        ...data,
        start_date: data.start_date.toISOString(),
        end_date: data.end_date.toISOString(),
      };

      const updatePayload = {
        index: index,
        item: updateVacation,
      };

      const updatedVacations = updateObjectInArray(
        currentVacations,
        updatePayload
      );

      const newVacations = { ...vacations, data: updatedVacations };

      setVacations(newVacations);
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setSuccessNotification({
        show: true,
        title: "Form request edited",
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openEditVacationSlideOver = (vacation, index) => {
    setSlideOver({
      childComponent: (
        <VacationRequestForm
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          vacation={vacation}
          actionCall={editVacation}
        />
      ),
      open: true,
    });
  };

  const fetchEmployeesDropdown = async function () {
    const authData = {
      action: "fetchEmployeesDropdown",
      userId: 1,
    };
    let callResult = await makePostApiCall(authData);

    if (callResult.success) {
      setEmployeesDropdownOptions([
        { value: -1, label: "Select Employee" },
        ...callResult.data,
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onEmployeeDropdownSelected = (eOption) => {
    const selectedEmployeeId = eOption.target.value;
    setSelectedEmployeeFilter(selectedEmployeeId);
  };

  return {
    employeesDropdownOptions,
    fetchEmployeesDropdown,
    getVacations,
    loading,
    onEmployeeDropdownSelected,
    vacations,
    openAddVacationSlideOver,
    openEditVacationSlideOver,
    selectedDate,
    setSelectedDate,
    setSlideOver,
    slideOver,
    selectedEmployeeFilter,
    setSuccessNotification,
    successNotification,
  };
}
