import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { updateObjectInArray } from "../../shared/utility";
import StoreDetailsForm from "../../components/forms/stores/StoreDetailsForm";
import ViewEmployeesForm from "../../components/forms/stores/ViewEmployeesForm";

export function StoresViewModel(logout) {
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState({
    data: [],
  });
  const [successNotification, setSuccessNotification] = useState({
    show: false,
    title: "",
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getStores = async function (search, page) {
    setLoading(true);
    const authData = {
      action: "fetchStores",
      userId: 1,
      search: search,
      pageNumber: page,
    };
    let callResult = await makePostApiCall(authData);
    setLoading(false);
    if (callResult.success) {
      setStores(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createStore = async function (data) {
    const authData = {
      action: "createStore",
      data: data,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      setSlideOver({
        childComponent: null,
        open: false,
      });
      getStores(null, 0);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openAddStoreSlideOver = () => {
    setSlideOver({
      childComponent: (
        <StoreDetailsForm
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={createStore}
        />
      ),
      open: true,
    });
  };

  const editStore = async function (data, index) {
    const authData = {
      action: "editStore",
      data: data,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      const currentStores = [...stores.data];
      const updateStore = {
        ...data,
      };

      const updatePayload = {
        index: index,
        item: updateStore,
      };

      const updatedStores = updateObjectInArray(currentStores, updatePayload);

      const newStores = { ...stores, data: updatedStores };

      setStores(newStores);
      setSlideOver({
        childComponent: null,
        open: false,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openEditStoreSlideOver = (store, index) => {
    setSlideOver({
      childComponent: (
        <StoreDetailsForm
          index={index}
          isEdit={true}
          setOpen={setSlideOver}
          store={store}
          actionCall={editStore}
        />
      ),
      open: true,
    });
  };

  const fetchStoreEmployees = async function (page, storeId) {
    const authData = {
      action: "fetchStoreEmployees",
      userId: 1,
      pageNumber: page,
      storeId: storeId,
    };
    let callResult = await makePostApiCall(authData);
    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openViewStoreEmployeesSlideOver = async function (store) {
    let callResult = await fetchStoreEmployees(1, store.id);

    setSlideOver({
      childComponent: (
        <ViewEmployeesForm employees={callResult} store={store} />
      ),
      open: true,
    });
  };

  return {
    getStores,
    loading,
    stores,
    openAddStoreSlideOver,
    openEditStoreSlideOver,
    openViewStoreEmployeesSlideOver,
    setSlideOver,
    slideOver,
    setSuccessNotification,
    successNotification,
  };
}
