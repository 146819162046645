import { useEffect, useRef } from "react";
import { BenefitsFormsViewModel } from "../../viewmodels/benefits/BenefitsFormsViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { formatDate, withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";
import SuccessNotification from "../../components/SuccessNotification";
import SelectMenu from "../../components/controls/SelectMenu";

function BenefitsForms(props) {
  const { logout } = props;
  const {
    clearFilters,
    deleteDocument,
    fetchEmployeesDropdown,
    filters,
    forms,
    getDocuments,
    loading,
    openAddAssignDocumentSlideOver,
    openSubmitDocumentSlideOver,
    setFilters,
    setSlideOver,
    slideOver,
    setSuccessNotification,
    successNotification,
    statusFilters,
  } = BenefitsFormsViewModel(logout);

  var currentPage = 1;
  useEffect(() => {
    getDocuments(null, currentPage);
    fetchEmployeesDropdown();
  }, []);

  const searchRef = useRef();

  const handleSearch = () => {
    getDocuments(searchRef.current.value, currentPage, filters.status);
  };

  const handlePagination = (newPage) => {
    currentPage = newPage;
    getDocuments(searchRef.current.value, newPage, filters.status);
  };

  return (
    <div>
      <div className="mt-10">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center bg-white px-4 py-5 border-b border-gray-200 sm:px-4 rounded-xl">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">
                Benefits Forms
              </h1>
              <div className="mt-5 flex rounded-md">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                  <input
                    type="search"
                    name="searchInput"
                    id="searchInput"
                    className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                    placeholder="Search by employee name"
                    ref={searchRef}
                  />
                </div>
                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
                <div className="ml-2">
                  <SelectMenu
                    handleInputChange={(e) =>
                      setFilters({ status: e.target.value })
                    }
                    name="syncMetadataFilter"
                    options={statusFilters}
                    selectedOptionValue={filters.status}
                    width="w-48"
                  />
                </div>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  onClick={() =>
                    getDocuments(searchRef.current.value, 0, filters.status)
                  }
                >
                  Filter
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                  onClick={() => clearFilters()}
                >
                  Clear
                </button>
                <button
                  type="button"
                  className="ml-5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => openAddAssignDocumentSlideOver()}
                >
                  Assign Document
                </button>
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                {loading ? (
                  <div>
                    <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                      <div className="animate-pulse flex space-x-4">
                        Loading
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-white">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Employee
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Date Added
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Date Completed
                          </th>

                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {forms.data.length === 0 ? (
                          <tr className="text-center">
                            <td className="py-2" colSpan="4">
                              No entries
                            </td>
                          </tr>
                        ) : (
                          forms.data.map((form, index) => (
                            <tr key={form.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {form.fname} {form.lname}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {form.status_text}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {formatDate(form.added_date)}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {formatDate(form.completed_date)}
                              </td>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <button
                                  onClick={() => {
                                    openSubmitDocumentSlideOver(form, index);
                                  }}
                                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                  Submit
                                </button>
                                <a
                                  href={
                                    "https://employee-api.eurobutcher.ca/documents/submissions/" +
                                    form.new_file_name
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  download={form.new_file_name}
                                  className="ml-2 rounded-md bg-yellow-600 px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                                >
                                  Download
                                </a>
                                <button
                                  onClick={() => {
                                    deleteDocument(form);
                                  }}
                                  className="ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-3xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
      <SuccessNotification
        show={successNotification.show}
        setShow={setSuccessNotification}
        title={successNotification.title}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BenefitsForms)
);
