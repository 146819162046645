import { Link } from "react-router-dom";
import { formatDate } from "../../shared/utility";

export default function CardFormsRow(props) {
  const { info } = props;

  return (
    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
      <div className="flex justify-between gap-x-4 py-3">
        <dt className="text-gray-500">
          {info.fname} {info.lname} - {info.type_text}
        </dt>
        <dd className="text-gray-700">
          <time dateTime={info.added_date}>{formatDate(info.added_date)}</time>
        </dd>
        <dd className="text-gray-700">
          <Link
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            to={"/" + info.href}
          >
            {info.buttonText}
          </Link>
        </dd>
      </div>
    </dl>
  );
}
