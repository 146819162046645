import { useEffect, useState } from "react";
import { useForm } from "../useForm";

import Input from "../../controls/Input";
import SelectMenu from "../../controls/SelectMenu";
import { classNames, convertToDefEventPara } from "../../../shared/utility";
import DatePickerInput from "../../controls/DatePickerInput";
import TextAreaInput from "../../controls/TextAreaInput";

const initialFValues = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  birthdate: new Date(),
  vacation_days: 0,
  start_date: null,
  end_date: null,
  benefits_start_date: null,
  benefits_end_date: null,
};

const employeeTypeOptions = [
  { label: "Employee", value: 0 },
  { label: "Admin", value: 1 },
];

export default function EmployeeDetailsForm(props) {
  const { employee, fetchStoresDropdown, setOpen, actionCall, isEdit } = props;

  const [storeOptions, setStoreOptions] = useState([
    { value: "", label: "Select store" },
  ]);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false);

  const handleSubmit = (e) => {
    e.preventDefault();

    actionCall(values);
  };

  useEffect(() => {
    if (isEdit) {
      setValues(employee);
    }
  }, [employee, setValues]);

  const fetchStoresDropdownCall = async function () {
    let callResult = await fetchStoresDropdown();

    if (callResult.success) {
      setStoreOptions(callResult.data);
    } else {
    }
  };

  useEffect(() => {
    fetchStoresDropdownCall();
  }, []);

  console.log("values.start_date", values.start_date);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              {isEdit ? "Employee details" : "Add new employee"}
            </h1>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="First name"
                  name="fname"
                  onChange={handleInputChange}
                  value={values.fname}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Last name"
                  name="lname"
                  onChange={handleInputChange}
                  value={values.lname}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Email"
                  name="email"
                  onChange={handleInputChange}
                  value={values.email}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Phone"
                  name="phone"
                  onChange={handleInputChange}
                  value={values.phone}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Address"
                  name="address"
                  onChange={handleInputChange}
                  value={values.address}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="City"
                  name="city"
                  onChange={handleInputChange}
                  value={values.city}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Zip"
                  name="zip"
                  onChange={handleInputChange}
                  value={values.zip}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Province"
                  name="province"
                  onChange={handleInputChange}
                  value={values.province}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="assigned_store"
                  options={storeOptions}
                  title="Assigned Store"
                  selectedOptionValue={isEdit ? employee.assigned_store : null}
                  width="w-full"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-4 gap-4">
              <div>
                <DatePickerInput
                  name="start_date"
                  onChange={(e) =>
                    handleInputChange(
                      convertToDefEventPara("start_date", e.target.value)
                    )
                  }
                  value={values.start_date}
                  placeholderText=""
                  label="Start Date"
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <DatePickerInput
                  name="end_date"
                  onChange={(e) =>
                    handleInputChange(
                      convertToDefEventPara("end_date", e.target.value)
                    )
                  }
                  value={values.end_date}
                  placeholderText=""
                  label="End Date"
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <DatePickerInput
                  name="benefits_start_date"
                  onChange={(e) =>
                    handleInputChange(
                      convertToDefEventPara(
                        "benefits_start_date",
                        e.target.value
                      )
                    )
                  }
                  value={values.benefits_start_date}
                  placeholderText=""
                  label="Benefits Start Date"
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <DatePickerInput
                  name="benefits_end_date"
                  onChange={(e) =>
                    handleInputChange(
                      convertToDefEventPara("benefits_end_date", e.target.value)
                    )
                  }
                  value={values.benefits_end_date}
                  placeholderText=""
                  label="Benefits End Date"
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <TextAreaInput
                  label="Note"
                  name="notes"
                  onChange={handleInputChange}
                  value={values.note}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="type"
                  options={employeeTypeOptions}
                  title="Employee type"
                  selectedOptionValue={isEdit ? employee.type : null}
                />
              </div>
              <div>
                <Input
                  label="Vacation days"
                  name="vacation_days"
                  onChange={handleInputChange}
                  value={values.vacation_days}
                  labelOn={true}
                  type="number"
                  min={0}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <Input
                  label="Password"
                  name="password"
                  onChange={handleInputChange}
                  value={values.password}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
              <div>
                <Input
                  label="Confirm Password"
                  name="password_confirmation"
                  onChange={handleInputChange}
                  value={values.password_confirmation}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className={classNames(
            isEdit ? "hidden" : "",
            "rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          )}
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Create"}
        </button>
      </div>
    </div>
  );
}
