import { useEffect } from "react";
import { useForm } from "../useForm";

import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import DatePickerInput from "../../controls/DatePickerInput";
import TextAreaInput from "../../controls/TextAreaInput";
import SelectMenu from "../../controls/SelectMenu";
import ImportMediaDropzone from "../../importMedia/ImportMediaDropzone";
import { classNames } from "../../../shared/utility";

const initialFValues = {
  start_date: "",
  end_date: "",
  total_dates: 0,
  note: "",
  status: "",
  reason: 0,
};

const absenceStatusOptions = [
  { label: "Submitted", value: 0 },
  { label: "Approved", value: 1 },
  { label: "Declined", value: 2 },
];

const absenceReasonOptions = [
  { label: "Select a reason", value: 0 },
  { label: "Maternity Leave", value: 1 },
  { label: "Parental Leave", value: 2 },
  { label: "Sick Leave", value: 3 },
  { label: "Family Responsibility Leave", value: 4 },
  { label: "Family Caregiver Leave", value: 5 },
  { label: "Infectious Disease Emergency Leave", value: 6 },
  { label: "Family Medical Leave", value: 7 },
  { label: "Critical Illness Leave", value: 8 },
  { label: "Child Death Leave", value: 9 },
  { label: "Crime-Related Child Dissappearance Leave", value: 10 },
  { label: "Organ-Donor Leave", value: 11 },
  { label: "Reservist Leave", value: 12 },
];

export default function AbsenceRequestForm(props) {
  const {
    employeesDropdownOptions,
    index,
    absence,
    setOpen,
    actionCall,
    isEdit,
  } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("start_date" in fieldValues) {
      temp.start_date = null;
      if (fieldValues.start_date === "") {
        temp.start_date = "Please fill out field.";
      }
    }

    if ("end_date" in fieldValues) {
      temp.end_date = null;
      if (fieldValues.end_date === "") {
        temp.end_date = "Please fill out field.";
      }
    }

    if ("reason" in fieldValues || fieldValues.reason === 0) {
      temp.reason = null;
      if (fieldValues.reason < 1) {
        temp.reason = "Please fill out field.";
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === null);
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, false, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      console.log("validated");
      if (isEdit) {
        actionCall(values, index);
      } else {
        actionCall(values);
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...absence,
        start_date: new Date(absence.start_date),
        end_date: new Date(absence.end_date),
      });
    }
  }, [absence, setValues]);

  return (
    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
      <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
        <div className="px-4 sm:px-6">
          <div className="flex items-start justify-between">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              {isEdit ? "Edit absence" : "Request new absence"}
            </Dialog.Title>
            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpen({ childComponent: null, open: false })}
              >
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
          <div>
            <div className={classNames(isEdit ? "hidden" : null)}>
              <SelectMenu
                handleInputChange={handleInputChange}
                name="employee_id"
                options={employeesDropdownOptions}
                width="w-48"
                selectedOptionValue={employeesDropdownOptions[0].value}
              />
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <DatePickerInput
                  label="Start date"
                  name="start_date"
                  onChange={handleInputChange}
                  value={values.start_date}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.start_date}
                />
              </div>
              <div>
                <DatePickerInput
                  label="End date"
                  name="end_date"
                  onChange={handleInputChange}
                  value={values.end_date}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                  error={errors.end_date}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <TextAreaInput
                  label="Note"
                  name="note"
                  onChange={handleInputChange}
                  value={values.note}
                  labelOn={true}
                  type="text"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-2">
              <div className="grid-col-2">
                <div className="flex space-x-2">
                  <label
                    htmlFor="document1"
                    className="block text-sm font-medium text-gray-900"
                  >
                    Document
                  </label>
                </div>
                <ImportMediaDropzone
                  fileName="document1"
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="reason"
                  options={absenceReasonOptions}
                  title="Reason for leave"
                  selectedOptionValue={isEdit ? absence.reason : null}
                  error={errors.reason}
                />
              </div>
            </div>
            <div className="mt-3 grid grid-cols-2 gap-4">
              <div>
                <SelectMenu
                  handleInputChange={handleInputChange}
                  name="status"
                  options={absenceStatusOptions}
                  title="Absence status"
                  selectedOptionValue={isEdit ? absence.status : null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-shrink-0 justify-end px-4 py-4">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
          onClick={() => setOpen({ childComponent: null, open: false })}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
          onClick={handleSubmit}
        >
          {isEdit ? "Save" : "Request"}
        </button>
      </div>
    </div>
  );
}
